












































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import { getGoodsTypeList, editGoodsType, delGoodsType } from '@/api/goods_type'
import SelectHotel from '@/components/select/select-hotel.vue' //酒店选择器
export default Vue.extend({
	components: { SelectHotel },
	data() {
		return {
			editGoodsType,
			...useTable({
				name: '商品分类',
				formdata: {
					/** ID */
					id: 0,
					/** 名称 */
					name: '',
					/** 酒店id */
					hotel_id: '',
				},
				getData: (query) => getGoodsTypeList(query).then((res) => res.data),
				delData: (row) => delGoodsType({ id: row.id }),
			}),
		}
	},
	created() {
		this.tableGetData()
	},
})
