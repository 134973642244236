






































































import Vue from 'vue'
// import { setModelField } from '@/api/common'
import { getOssUrl } from '@/api/common'
import { useTable } from '@/components/table/table-hook'
import { getGoodsList, editGoods, delGoods } from '@/api/meal_goods_list'
import SelectHotel from '@/components/select/select-hotel.vue' //酒店选择器
import SelectGoodsType from '@/components/select/select-goods_type.vue' //订餐商品类型选择器
import { cloneDeep } from 'lodash'
import ModelFieldEditor from '@/components/form/model-field-editor.vue'
export default Vue.extend({
	components: { SelectHotel, SelectGoodsType, ModelFieldEditor },
	data() {
		return {
			baseUrl: '',
			oldBaseUrl: '',
			editGoods,
			...useTable({
				name: '订餐商品',
				formdata: {
					id: 0,
					good_type_ids: [],
					images: [],
					name: '',
					hotel_id: '',
					original_price: 0,
					price: 0,
				},
				query: {
					sort_key: 'sort',
					sort_type: 'desc',
				},
				getData: (query) => getGoodsList(query).then((res) => res.data),
				delData: (row) => delGoods({ id: row.id }),
			}),
		}
	},
	methods: {
		// changeTj(row) {
		// 	setModelField({
		// 		model: 'Hotel',
		// 		id: row.id,
		// 		field: 'is_recommend',
		// 		value: row.is_recommend == 1 ? 0 : 1,
		// 	}).then((res) => {
		// 		this.$message.success('操作成功！')
		// 		if (row.is_recommend == 1) {
		// 			row.is_recommend = 0
		// 		} else {
		// 			row.is_recommend = 1
		// 		}
		// 	})
		// },
		onSubmit(row) {
			let param = row
			let base_url_length = this.baseUrl.split('').length
			if (param.images != null) {
				let coverImage = [] as any
				param.images.forEach((el) => {
					if (el.indexOf(this.baseUrl) !== -1) {
						el = el.slice(base_url_length)
						if (el.indexOf(this.baseUrl) !== -1) {
							el = el.slice(base_url_length)
						}
					}
					coverImage.push(el)
				})
				param.images = JSON.stringify(coverImage) // 多图
			}
			// param.images = JSON.stringify(param.images) //详情图
			param.good_type_ids = JSON.stringify(param.good_type_ids) //商品类型
			return editGoods(param).then(() => this.$message.success('保存成功') && this.tableGetData())
		},
		getOssUrl() {
			getOssUrl().then((res) => {
				this.baseUrl = res.data.domain
				this.oldBaseUrl = res.data.old_domain
			})
		},
		edit(row) {
			const params = cloneDeep(row)
			params.images = row.images ? JSON.parse(row.images) : []
			;(this.$refs.formDialogRef as any).open(params)
		},
	},
	created() {
		this.getOssUrl()
		this.tableGetData()
	},
})
